import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {HelperService, SingleSignOnService, SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {BehaviorSubject, EMPTY, from, Observable, of} from 'rxjs'
import {catchError, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {UserService} from './user.service'

/**
 * Indicates if we are even trying to log in
 */
export enum LoggedInStatus {
  NOT_STARTED,
  STARTED,
  IN_PROGRESS,
  FAILED,
  COMPLETE
}

/**
 * The idea is that lower number is more access so that we can say role < 3 etc.
 * May or may not work.
 */
export enum FileMonitoringRole {
  ADMIN = 1,
  CREDIT = 2,
  INTERNAL_SUPPORT = 3,
  CUSTOMER_SUPPORT = 4,
  OFFICE = 5,
  EMPLOYEE = 6,
  TESTER = 7,
  FM_ADMIN = 8,
  BLANCO_ADMIN = 9
}

/**
 * The logged in state, either logged in or not.
 */
export interface LoggedInState {
  /**
   * The logged in state
   */
  loggedIn: boolean

  /**
   * If we want to communicate status somehow we do this in this property.
   */
  statusMessage?: string

  /**
   * Where we are in the process of logging in.
   */
  state: LoggedInStatus

  /**
   * If login fails this message will be set to something sensible.
   */
  errorMessage?: string

  /**
   * More detailed error information
   */
  errorDetail?: string

  /**
   * The access token as such
   */
  accessToken?: string

  /**
   * An array of roles
   */
  roles?: FileMonitoringRole[]
}


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Listen to this when you want to know if the login state has changed.
   */
  public logInState$: Observable<LoggedInState | null>

  /**
   * The access token, primarily needed for the auth interceptor
   */
  public accessToken$ = new BehaviorSubject<string | null>(null)

  /**
   * Private so that we prevent others from publishing
   */
  private pLogInState$ = new BehaviorSubject<LoggedInState | null>(null)

  constructor(
    private helperService: HelperService,
    private router: Router,
    private ssoService: SingleSignOnService,
    private userService: UserService
  ) {
    this.logInState$ = this.pLogInState$.asObservable()
  }

  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    return this.sso()
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge
   */
  public sso = (): Observable<string> | never => {
    return this.ssoService.getToken(environment.userServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We MUST log out if the SSO service says we are logged out!
          this.reset()
          return EMPTY
        })
      )
  }

  public logout(): void {
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.userServiceUrl).subscribe()
    // Reset all values and navigates to login
    this.reset()
  }


  public reset(): void {
    // Emit login data to subscribers
    this.pLogInState$.next({
      loggedIn: false,
      state: LoggedInStatus.NOT_STARTED
    })

    this.userService.currentUser$.next(null)
    this.router.navigate(['/']).then()
  }


  /**
   * Fetch and validate an access token. If ok send login complete status.
   * This can be called by outsiders to retrieve status if they were not
   * in play when the message was sent last time.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payload = HelperService.GetTokenPayload(token)
    if (payload) {
      this.accessToken$.next(token)
      //
      // What we want to do here is to convert the roles ['admin', 'credit'] to numbers that we
      // use in the app [0,1], so we create a map with keys and then fetch from the map with
      // strings ('admin' etc.) that we find in the payload.
      const roleMap = {
        admin: FileMonitoringRole.ADMIN,
        credit: FileMonitoringRole.CREDIT,
        internalSupport: FileMonitoringRole.INTERNAL_SUPPORT,
        customerSupport: FileMonitoringRole.CUSTOMER_SUPPORT,
        office: FileMonitoringRole.OFFICE,
        employee: FileMonitoringRole.EMPLOYEE,
        tester: FileMonitoringRole.TESTER,
        fmAdmin: FileMonitoringRole.FM_ADMIN
      }
      // The filter is b/c the user may have other roles not present in this application
      const roles = payload.roles.map((role) => roleMap[role]).filter((i) => i !== undefined)
      // Emit login data to subscribers
      this.pLogInState$.next({
        loggedIn: true,
        state: LoggedInStatus.COMPLETE,
        roles
      })

      this.setUserData()
      return from(this.router.navigate(['base']))
    }
    /**
     * If we are called with invalid tokens we reset all log in data
     * we do not explicitly LOG OUT!
     */
    this.reset()
    return of(true)
  }

  /**
   * In many cases we also want to fetch the user info we do this
   * totally asynchronous and happily accept that the access token
   * is set properly etc.
   *
   * In Legacy applications we have used this to trigger a reset, which is
   * bad.
   */
  private setUserData(): void {
    this.helperService.getCurrentUser(`${environment.userServiceUrl}`)
      .subscribe({
        next: (user: SparbankenUser) => {
          this.userService.currentUser$.next(user)
        }
      })
  }
}